import { takeLatest, call, put } from "redux-saga/effects";
import { pathObj } from "../../services/apiPath";
import { apiGet, apiPost } from "services/httpServices";
import { successToast } from "utils/toastMessage";

function* getRestaurantMenu(action) {
  let { callback } = action;
  let { params } = action.payload;
  callback && callback(true, true, null);
  try {
    const response = yield call(apiGet, pathObj.RESTAURANT_MENU, params);
    if (response.status == 200) {
      const result = {
        menu: response?.data?.data,
        restaurant: response?.data?.restaurant_data,
      };

      if (response?.data?.is_previous_date) {
        yield put({
          type: "GET_ASAP_ADVANCE_TYPE",
          payload: 1,
        });
        yield put({
          type: "ASAP_DATE",
          payload: "",
        });
        yield put({
          type: "GET_TIME",
          payload: {},
        });
        yield put({
          type: "GET_DATE_TIME_ON_MENU",
          payload: "",
        });
      }
      yield put({
        type: "SET_RESTAURANT_MENU_DATA",
        payload: result,
      });
      callback && callback(false, false, result);

      yield put({
        type: "GET_SELECTED_RESTAURANT_DATA",
        payload: response?.data?.restaurant_data,
      });

      if (response?.data?.restaurant_data?.city_comment) {
        yield put({
          type: "SHOW_TICKER",
          payload: true,
        });
        yield put({
          type: "SHOW_TICKER_TEXT",
          payload: response?.data?.restaurant_data?.city_comment,
        });
      } else {
        yield put({
          type: "SHOW_TICKER",
          payload: false,
        });
        yield put({
          type: "SHOW_TICKER_TEXT",
          payload: "",
        });
      }
    } else {
      callback && callback(false, true, null);
      yield put({
        type: "SET_RESTAURANT_MENU_DATA",
        payload: {},
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_RESTAURANT_MENU_DATA",
      payload: {},
    });
    console.log(error);
  }
}

//******** For header Product List on Search *******//
function* getRestaurantMenuHeaderSearch(action) {
  let { callback } = action;
  let { params } = action.payload;
  callback && callback(true, true);
  try {
    const response = yield call(apiGet, pathObj.RESTAURANT_MENU, params);
    if (response.status == 200) {
      callback && callback(false, false);
      yield put({
        type: "SET_RESTAURANT_MENU_LIST_HEADER",
        payload: response?.data?.data,
      });
    } else {
      callback && callback(false, true);
      yield put({
        type: "SET_RESTAURANT_MENU_LIST_HEADER",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(false, true);
    yield put({
      type: "SET_RESTAURANT_MENU_LIST_HEADER",
      payload: [],
    });
    console.log(error);
  }
}

function* getProductAddons(action) {
  let { callback } = action;
  callback && callback(null, true, true);
  let { params } = action.payload;
  const productID = params.productsId;
  const group_id = params.group_id;
  const is_cart_view = params.is_cart_view;
  const customers_basket_id = params.customers_basket_id;

  try {
    const response = yield call(apiGet, pathObj.ADDONS + productID, {
      group_id,
      is_cart_view,
      customers_basket_id,
    });
    if (response.status == 200) {
      callback && callback(response?.data, false, false);
      yield put({
        type: "SET_PRODUCT_ADDONS",
        payload: response?.data?.data?.options,
      });
      /*  yield put({
        type: "SET_PRODUCT_ADDONS",
        payload: [
          {
            options_id: 122160,
            products_options_name: "Dipping Sauce",
            attributes_min_select: 1,
            attributes_max_select: 2,
            products_options_type: 3,
            min_aggregate_quantity: 1,
            support_choice_quantities: false,
            max_aggregate_quantity: 0,
            increment_choice_quantity: 1,
            optionValues: [
              {
                options_values_id: 1293864,
                products_options_values_name: "Mustard",
                options_values_price: "0.0000",
                attributes_required: 0,
                attributes_default: 0,
                products_attributes_id: 2407834,
                attributes_price_base_included: 0,
                quantity: 1,
                options_value_text: "",
                min_choice_quantity: 1,
                max_choice_quantity: 0,
                second_level_options: [],
              },
              {
                options_values_id: 1293865,
                products_options_values_name: "Mayonnaise",
                options_values_price: "0.0000",
                attributes_required: 0,
                attributes_default: 0,
                products_attributes_id: 2407835,
                attributes_price_base_included: 0,
                quantity: 1,
                options_value_text: "",
                min_choice_quantity: 1,
                max_choice_quantity: 0,
                second_level_options: [],
              },
              {
                options_values_id: 1293866,
                products_options_values_name: "Ranch",
                options_values_price: "0.0000",
                attributes_required: 0,
                attributes_default: 0,
                products_attributes_id: 2407836,
                attributes_price_base_included: 0,
                quantity: 1,
                options_value_text: "",
                min_choice_quantity: 1,
                max_choice_quantity: 0,
                second_level_options: [],
              },
              {
                options_values_id: 1293867,
                products_options_values_name: "Ketchup",
                options_values_price: "0.0000",
                attributes_required: 0,
                attributes_default: 0,
                products_attributes_id: 2407837,
                attributes_price_base_included: 0,
                quantity: 1,
                options_value_text: "",
                min_choice_quantity: 1,
                max_choice_quantity: 0,
                second_level_options: [],
              },
            ],
          },
          {
            options_id: 40,
            products_options_name: "Special Instructions",
            attributes_min_select: 0,
            attributes_max_select: 0,
            products_options_type: 1,
            min_aggregate_quantity: 1,
            max_aggregate_quantity: 0,
            increment_choice_quantity: 1,
            optionValues: [
              {
                options_values_id: 0,
                products_options_values_name: "TEXT",
                options_values_price: "0.0000",
                attributes_required: 0,
                attributes_default: 0,
                products_attributes_id: 2407832,
                attributes_price_base_included: 0,
                quantity: 1,
                options_value_text: "",
                min_choice_quantity: 1,
                max_choice_quantity: 0,
                second_level_options: [],
              },
            ],
          },
        ],
      }); */
      yield put({
        type: "SET_PRODUCT_ADDONS_META",
        payload: {
          products_quantity_order_max:
            response?.data?.data?.products_quantity_order_max,
          products_quantity_order_min:
            response?.data?.data?.products_quantity_order_min,
          quantity_increment: response?.data?.data?.quantity_increment,
        },
      });
      /* yield put({
        type: "SET_PRODUCT_ADDONS_META",
        payload: {
          products_quantity_order_max:
            response?.data?.data?.products_quantity_order_max,
          products_quantity_order_min:
            2,
          quantity_increment: 2,
        },
      }); */
      yield put({
        type: "SET_PRODUCT_BASKET_ID",
        payload: response?.data?.customers_basket_id,
      });
    } else {
      callback && callback(null, false, true);
      yield put({
        type: "SET_PRODUCT_ADDONS",
        payload: [],
      });
      yield put({
        type: "SET_PRODUCT_ADDONS_META",
        payload: {},
      });
      yield put({
        type: "SET_PRODUCT_BASKET_ID",
        payload: "",
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(null, false, true);
    yield put({
      type: "SET_PRODUCT_ADDONS",
      payload: [],
    });
    yield put({
      type: "SET_PRODUCT_ADDONS_META",
      payload: {},
    });
    yield put({
      type: "SET_PRODUCT_BASKET_ID",
      payload: "",
    });
    console.log(error);
  }
}

function* handelAddUpdateAttributeForCart(action) {
  let { callback } = action;
  callback && callback(null, null, true, true);
  try {
    const response = yield call(
      apiPost,
      pathObj.ADD_UPDATE_ATTRIBUTES,
      action.payload
    );
    if (response.status == 200) {
      // successToast(response.data.message);

      callback && callback(response?.data, null, false, false);
      // yield put({ type: "SET_FORGOT_PASSWORD", payload: action.payload });
    } else {
      callback && callback(null, null, false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(null, error?.response?.data, false, true);
    console.log(error);
  }
}

function* fetchCartList(action) {
  let { callback } = action;
  let { params, resultParams } = action.payload;
  callback && callback(null, true, true);
  const dataPayload = resultParams ? resultParams : params;
  try {
    const response = yield call(apiGet, pathObj.CART_LIST, dataPayload);
    if (response.status === 200) {
      callback && callback(response?.data, false, false);
      yield put({
        type: "SET_CART_LIST",
        payload: response?.data?.data,
      });

      yield put({
        type: "PREVENT_RESTAURANT_ID",
        payload: response?.data?.restaurant,
      });

      yield put({
        type: "PREVENT_RESTAURANT_NAME",
        payload: response?.data?.restaurant?.name,
      });

      if (response?.data?.data?.length == 0) {
        yield put({
          type: "SET_RE_ORDER",
          payload: {},
        });
      }
    } else {
      callback && callback(null, false, true);
      yield put({
        type: "SET_CART_LIST",
        payload: [],
      });
      console.log("Something went wrong");
    }
  } catch (error) {
    callback && callback(null, false, true);
    yield put({
      type: "SET_CART_LIST",
      payload: [],
    });
    console.log(error);
  }
}

function* handelDeleteCartItem(action) {
  let { callback } = action;
  callback && callback(true, true);
  let { params } = action.payload;
  try {
    const response = yield call(apiPost, pathObj.DELETE_CART_ITEM, params);
    if (response.status == 200) {
      // successToast(response.data.message);
      callback && callback(false, false);
    } else {
      callback && callback(false, true);
      console.log("Something went wrong");
    }
  } catch (error) {
    callback(false, true, error);
    console.log(error);
  }
}

export function* RasturantMenuWatcher() {
  yield takeLatest("GET_RESTAURANT_MENU_DATA", getRestaurantMenu);
  yield takeLatest(
    "GET_RESTAURANT_MENU_LIST_HEADER",
    getRestaurantMenuHeaderSearch
  );
  yield takeLatest("GET_PRODUCT_ADDONS", getProductAddons);
  yield takeLatest("GET_ADD_UPDATE_ATTRIBUTE", handelAddUpdateAttributeForCart);
  yield takeLatest("GET_CART_LIST", fetchCartList);
  yield takeLatest("DELETE_CART_ITEM", handelDeleteCartItem);
}
