import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import deleteIcon from "assets/images/delete-icon.svg";
import Loader from "components/shared/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  SHOE_ADDONS_MODAL_CART,
  SHOW_CART,
} from "store/Action/RasturantMenuAction";
import moment from "moment";
import {
  GET_HIT_PLACE_ORDER_KEY,
  GET_VERIFY_ADDRESS_DATE_TIME,
} from "store/Action/CheckoutAction";
import DeleteCartModal from "components/restaurants/Cart/DeleteCartModal";
import { textTruncateMore } from "helpers/textTruncate/TextTruncate";
import { SET_REWARD_POINT_CARD_SELECTION } from "store/Action/Sidebar/RewardsAction";
import { SET_PURCHASE_CARD_SELECTION } from "store/Action/Sidebar/PurchaseGiftCardAction";
import { DEVELOPMENT_PRODUCTION } from "services/path";
import AddonsModal from "components/restaurants/Attributes/AddonsModal";
import {
  GET_SHOW_RESTAURANT_CART,
  SHOW_BOTTOM_NAVIGATION_SEARCH,
} from "store/Action/HomeAction";
import "./header-cart.css";

const HeaderCart = ({ handleHeaderCart, setHeaderCart }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  var menuId = location.pathname?.replace(/^\D+/g, "");
  var menuUrl = location.pathname?.replace(menuId, "");
  const Token = localStorage.getItem("access_token");
  const GuestToken = sessionStorage.getItem("__g_access_token");

  // All Selectors
  const {
    CART_LIST,
    ASAP_DATE_STATE,
    ASAP_TIME_STATE,
    ASAP_ADVANCE_STATE,
    DEFAULT_ADDRESS,
    RESTAURANTS,
    PREVENT_RESTAURANT_NAME_STATE,
    SELECTED_RESTAURANT_DATA_STATE,
    PREVENT_RESTAURANT_Obj_STATE,
    SERVICE_TYPE,
    SHOW_MODAL_CART,
    GROUP_ID,
  } = useSelector((store) => ({
    CART_LIST: store?.RasturantMenuReducer?.cartList ?? [],
    CART_TRIGGER_LOADER:
      store?.RasturantMenuReducer?.cartTriggerLoader ?? false,
    ASAP_DATE_STATE: store?.ResturantReducer?.asapDate,
    ASAP_TIME_STATE: store?.ResturantReducer?.timeSlot,
    ASAP_ADVANCE_STATE: store?.ResturantReducer?.asapAdvance,
    DEFAULT_ADDRESS: store.ProfileReducer.defaultAddress,
    RESTAURANTS: store.RasturantMenuReducer.menuDetails.restaurant,
    PREVENT_RESTAURANT_NAME_STATE:
      store?.RasturantMenuReducer?.restaurantPrvName ?? "",
    PREVENT_RESTAURANT_Obj_STATE:
      store?.RasturantMenuReducer?.restaurantPrvId ?? {},
    SELECTED_RESTAURANT_DATA_STATE:
      store?.ResturantReducer?.selectedRestaurantData ?? {},
    SERVICE_TYPE: store.ResturantReducer.serviceType,
    SHOW_MODAL_CART: store.RasturantMenuReducer.showAddonsModalCart,
    GROUP_ID: store?.RasturantMenuReducer?.groupId,
  }));

  var dynamicUrl = `/${SELECTED_RESTAURANT_DATA_STATE?.slug}`;

  // ALL STATE
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeProductItem, setActiveProductItem] = useState("");
  const [verifyAddDateTimeLoader, setVerifyAddDateTimeLoader] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [activeProductName, setActiveProductName] = useState("");
  const [activeProduct, setActiveProduct] = useState({});
  const [activeBasketId, setActiveBasketId] = useState("");
  const [activeProductId, setActiveProductId] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const toggleDeleteModal = (data) => {
    setShowDeleteModal(!showDeleteModal);
    setActiveProductItem(data);
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [showUnavilableError, setShowUnavilableError] = useState(false);
  const handelCheckout = () => {
    if (CART_LIST.length !== 0) {
      const initialCart = CART_LIST[0];
      if (initialCart?.products_model == "E_GIFT_CARD") {
        navigate("/giftcard-checkout");
      } else if (initialCart?.products_model == "E_GIFT_CARD_NON_REWARDS") {
        navigate("/card-checkout");
      } else {
        if (initialCart?.products_model === null) {
          const result = CART_LIST.some((cart) => !cart.is_product_available);
          setShowUnavilableError(result);
          if (result) {
            return;
          }
        }
        if (!Token && !GuestToken) {
          dispatch(GET_HIT_PLACE_ORDER_KEY("OrderCheckout"));
          navigate(`/sign-in?group_id=${GROUP_ID}`);
          return;
        }
        let verifyPayload = {};

        if (initialCart?.products_model === null) {
          if (ASAP_DATE_STATE && ASAP_TIME_STATE?.time) {
            const currentDate = moment(ASAP_DATE_STATE).format("YYYY-MM-DD");
            const time = ASAP_TIME_STATE?.time?.split("-");
            const Time = time[1]?.replace(/\s/g, "");
            const currentTime = moment(Time, "h:mm A").format("HH:mm");
            verifyPayload = {
              restaurant_id: PREVENT_RESTAURANT_Obj_STATE?.id
                ? PREVENT_RESTAURANT_Obj_STATE?.id
                : RESTAURANTS?.id,
              lat_from: DEFAULT_ADDRESS?.address_lat,
              lng_from: DEFAULT_ADDRESS?.address_lng,
              asap_or_advance: ASAP_ADVANCE_STATE,
              date: currentDate,
              time: currentTime,
              service_type: SERVICE_TYPE,
            };
          } else {
            verifyPayload = {
              restaurant_id: PREVENT_RESTAURANT_Obj_STATE?.id
                ? PREVENT_RESTAURANT_Obj_STATE?.id
                : RESTAURANTS?.id,
              lat_from: DEFAULT_ADDRESS?.address_lat,
              lng_from: DEFAULT_ADDRESS?.address_lng,
              asap_or_advance: ASAP_ADVANCE_STATE,
              service_type: SERVICE_TYPE,
            };
          }
        } else {
          if (ASAP_DATE_STATE && ASAP_TIME_STATE?.time) {
            const currentDate = moment(ASAP_DATE_STATE).format("YYYY-MM-DD");
            const time = ASAP_TIME_STATE?.time?.split("-");
            const Time = time[1]?.replace(/\s/g, "");
            const currentTime = moment(Time, "h:mm A").format("HH:mm");
            verifyPayload = {
              // restaurant_id: 1997,
              restaurant_id: DEVELOPMENT_PRODUCTION,
              lat_from: DEFAULT_ADDRESS?.address_lat,
              lng_from: DEFAULT_ADDRESS?.address_lng,
              asap_or_advance: ASAP_ADVANCE_STATE,
              date: currentDate,
              time: currentTime,
              service_type: SERVICE_TYPE,
            };
          } else {
            verifyPayload = {
              restaurant_id: DEVELOPMENT_PRODUCTION,
              lat_from: DEFAULT_ADDRESS?.address_lat,
              lng_from: DEFAULT_ADDRESS?.address_lng,
              asap_or_advance: ASAP_ADVANCE_STATE,
              service_type: SERVICE_TYPE,
            };
          }
        }

        dispatch(
          GET_VERIFY_ADDRESS_DATE_TIME(
            { verifyPayload: verifyPayload },
            (loading, error, errObj) => {
              if (error) {
                setErrorMessage(errObj);
              }
              setVerifyAddDateTimeLoader(loading);

              if (!error) {
                if (initialCart?.products_model === "GIFT_CARD") {
                  setHeaderCart(false);
                  dispatch(GET_SHOW_RESTAURANT_CART(false));
                  dispatch(SHOW_CART(false));
                  navigate("/giftcard-checkout");
                } else if (
                  initialCart?.products_model === "GIFT_CARD_NON_REWARDS"
                ) {
                  navigate("/card-checkout");
                  setHeaderCart(false);
                  dispatch(GET_SHOW_RESTAURANT_CART(false));
                  dispatch(SHOW_CART(false));
                } else {
                  setHeaderCart(false);
                  dispatch(GET_SHOW_RESTAURANT_CART(false));
                  dispatch(SHOW_CART(false));
                  navigate("/checkout");
                }
              }
            }
          )
        );
        // }
      }
    }
  };

  useEffect(() => {
    setShowUnavilableError(false);
  }, [CART_LIST]);

  const handelShowModal = (item) => {
    if (CART_LIST?.length !== 0) {
      const initialCart = CART_LIST[0];
      if (initialCart?.products_model == "E_GIFT_CARD") {
        dispatch(SET_REWARD_POINT_CARD_SELECTION("eGiftCard"));
        navigate("/redeem-points");
        handleHeaderCart();
      } else if (initialCart?.products_model == "GIFT_CARD") {
        dispatch(SET_REWARD_POINT_CARD_SELECTION("GiftCard"));
        navigate("/redeem-points");
        handleHeaderCart();
      } else if (initialCart?.products_model == "E_GIFT_CARD_NON_REWARDS") {
        dispatch(SET_PURCHASE_CARD_SELECTION("eGiftCard"));
        navigate("/buy-gift-card");
        handleHeaderCart();
      } else if (initialCart?.products_model == "GIFT_CARD_NON_REWARDS") {
        dispatch(SET_PURCHASE_CARD_SELECTION("GiftCard"));
        navigate("/buy-gift-card");
        handleHeaderCart();
      } else {
        //handleHeaderCart();
        setActiveProductId(item?.products_id);
        setActiveProductName(item?.product_name);
        setActiveProduct(item);
        dispatch(SHOE_ADDONS_MODAL_CART(!SHOW_MODAL_CART));
        setActiveBasketId(item?.customers_basket_id);
      }
    }
  };

  useEffect(() => {
    // if (menuUrl == "/restaurants-menu/") {
    if (location?.pathname == dynamicUrl) {
      handleHeaderCart();
    }
  }, [menuUrl]);

  return (
    <>
      <div className="header-notificatin-wrapper minicart_header">
        <div className="d-flex align-items-center  pb-1 close_position">
          <Button
            className="bg-transparent border-0 text-dark p-0"
            onClick={() => handleHeaderCart()}
          >
            <CloseIcon />
          </Button>
        </div>
        {location.pathname == "/restaurants" && windowSize.innerWidth < 767 ? (
          <div
            className="rest-header"
            style={{
              marginTop: "-50px",
              /* position: "absolute", */
              width: "100%",
            }}
          >
            <div className="restra-container mb-0">
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link
                  to={
                    PREVENT_RESTAURANT_NAME_STATE &&
                    PREVENT_RESTAURANT_NAME_STATE === "All Restaurants"
                      ? `/restaurants`
                      : `/${PREVENT_RESTAURANT_Obj_STATE.slug}?q=fd`
                  }
                  onClick={() => {
                    handleHeaderCart();
                  }}
                >
                  {PREVENT_RESTAURANT_NAME_STATE
                    ? PREVENT_RESTAURANT_NAME_STATE === "All Restaurants"
                      ? "All Stores"
                      : PREVENT_RESTAURANT_NAME_STATE
                    : ""}
                </Link>
              </h3>
            </div>
          </div>
        ) : (
          <div className="rest-header">
            <div className="menu-heading d-md-block">
              <p className="fs-6">Your Order From</p>
            </div>

            <div
              className={`restra-container mb-0 ${
                menuUrl == "/" ? "d-none" : ""
              }`}
            >
             {/*  <span className={`d-md-block `}>From</span> */}
              <h3 className="d-md-block">
                <Link
                  to={
                    PREVENT_RESTAURANT_NAME_STATE &&
                    PREVENT_RESTAURANT_NAME_STATE === "All Restaurants"
                      ? `/restaurants`
                      : `/${PREVENT_RESTAURANT_Obj_STATE.slug}?q=fd`
                  }
                  onClick={() => {
                    handleHeaderCart();
                  }}
                >
                  {PREVENT_RESTAURANT_NAME_STATE
                    ? PREVENT_RESTAURANT_NAME_STATE === "All Restaurants"
                      ? "All Stores"
                      : PREVENT_RESTAURANT_NAME_STATE
                    : ""}
                </Link>
              </h3>
            </div>
          </div>
        )}
        {errorMessage && (
          <p class="error-message text-start py-2 px-1 text-danger">
            {errorMessage?.response?.data?.message}
          </p>
        )}
        <div className="shopping-cart-items">
          <ul>
            {CART_LIST &&
              CART_LIST?.length !== 0 &&
              CART_LIST?.map((item, index) => {
                return (
                  <li
                    className="justify-content-between align-items-baseline no-hover"
                    key={index}
                  >
                    <div className="d-flex ">
                      <div
                        className="item-quantity  oneX-class"
                        onClick={() => handelShowModal(item)}
                        style={{ cursor: "text" }}
                      >
                        <span className="quantity-count ">
                          {item?.customers_basket_quantity + "X"}
                        </span>
                      </div>
                      <div className="cart-item d-flex justify-content-between pe-0 align-items-baseline">
                        <h5
                          className="mb-0 a-link"
                          onClick={() => handelShowModal(item)}
                        >
                          <span className="fs-6 " title={item?.product_name}>
                            {textTruncateMore(item?.product_name, 30)}
                          </span>

                          <div className="item-price d-flex align-items-center mb-0 ps-0">
                            {item?.reward_points && item?.reward_points > 0 ? (
                              <span className="fs-6">
                                {parseFloat(item?.reward_points).toFixed(2)}{" "}
                                pts. ($
                                {parseFloat(item?.final_price)?.toFixed(2)})
                              </span>
                            ) : (
                              <span className="fs-6">
                                ${parseFloat(item?.final_price)?.toFixed(2)}
                              </span>
                            )}
                          </div>
                        </h5>

                        <Button
                          className="bg-transparent border-0 pe-0"
                          onClick={() => toggleDeleteModal(item)}
                        >
                          <img src={deleteIcon} alt="No_Image" />
                        </Button>
                      </div>
                    </div>
                    {!item?.is_product_available && showUnavilableError && (
                      <p className="item-not-available">
                        Item not available at selected time
                      </p>
                    )}

                    <div>
                      {item?.recipient_email && (
                        <p className="mb-0">
                          <strong>Recipient's Email Address</strong> :{" "}
                          <span>{item?.recipient_email}</span>
                        </p>
                      )}
                      {item?.email_subject && (
                        <p className="mb-0">
                          <strong>Email Subject</strong> :{" "}
                          <span>{item?.email_subject}</span>
                        </p>
                      )}
                      {item?.comment && (
                        <p className="mb-0">
                          <strong>Message</strong> :{" "}
                          <span>{item?.comment}</span>
                        </p>
                      )}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        {CART_LIST?.length !== 0 && menuUrl !== "/sign-in" && (
          <div className=" modal-bottom-actions-cart  modal-footer-cart">
            <Button
              className={`common-btn w-100 ck-btn ${
                menuUrl === "/restaurants" ? "restaurants-Checkout-btn-1" : ""
              }`}
              onClick={() => {
                handelCheckout();
                dispatch(SHOW_BOTTOM_NAVIGATION_SEARCH(false));
              }}
            >
              <span className="d-flex justify-content-center align-item-center">
                Checkout
                {verifyAddDateTimeLoader && (
                  <Loader
                    Style={{
                      marginLeft: "20px",
                      marginTop: "4px",
                    }}
                    classes="widthtwinty"
                  />
                )}
              </span>
            </Button>
          </div>
        )}
      </div>
      {SHOW_MODAL_CART && (
        <AddonsModal
          show={SHOW_MODAL_CART}
          onHide={handelShowModal}
          productName={activeProductName}
          activeProduct={activeProduct}
          cartView={1}
          cartBasketId={activeBasketId}
        />
      )}
      {showDeleteModal && (
        <DeleteCartModal
          show={showDeleteModal}
          onHide={() => toggleDeleteModal()}
          activeProduct={activeProductItem}
        />
      )}
    </>
  );
};

export default HeaderCart;
