import "bootstrap/dist/css/bootstrap.min.css";
import { FoodDudesRoutes } from "./routes";
import { ToastContainer, Slide } from "react-toastify";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../src/index.css";
import "./assets/css/style.css";
import "./assets/css/media.css";
import "./assets/css/devStyles.css";
import Sidebar from "pages/Sidebar/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { SHOW_SIDE_BAR } from "store/Action/HomeAction";
import BottomNavigationMenu from "components/bottomNavigationMenu/BottomNavigationMenu";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Sentry from "@sentry/react";

// import MyAccount from "pages/Sidebar/myAccount/MyAccount";
// import Address from "pages/auth/address/Address";
// import BottomNavigationMenu from "components/bottomNavigationMenu/BottomNavigationMenu";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const path = location?.pathname?.replace("/", "");
  const url = location?.pathname;
  const Token = localStorage.getItem("access_token");
  const navigate = useNavigate();

  //ALL SELECTORS
  const { VISIBLE_SIDEBAR, MENU_CART_SHOW } = useSelector((store) => ({
    VISIBLE_SIDEBAR: store.HomeReducer.sidebarVisible,
    MENU_CART_SHOW: store?.RasturantMenuReducer?.menuCartShow ?? false,
  }));

  useEffect(() => {
    dispatch(SHOW_SIDE_BAR(false));
    dispatch({
      type: "SET_ACCOUNT_MODAL",
      payload: false,
    });
    dispatch({
      type: "SET_ADDRESS",
      payload: false,
    });
  }, [url]);

  return (
    <div>
      <ToastContainer
        // autoClose={2000}
        position="top-center"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        transition={Slide}
        limit={1}
      />
      {/* <Header /> */}
      {!path ? <></> : <Header />}
      {Token && VISIBLE_SIDEBAR && <Sidebar />}
      {VISIBLE_SIDEBAR && <Sidebar />}
      <FoodDudesRoutes />
      {path && <BottomNavigationMenu />}

      {!path ? (
        <></>
      ) : (
        <div className="d-md-block d-none">
          <Footer />
        </div>
      )}
    </div>
  );
}

// export default App;
export default Sentry.withProfiler(App);
