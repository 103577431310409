const DRIVER_TIP = (data) => {
  return {
    type: "DRIVER_TIP",
    payload: data,
  };
};
const CHECKOUT_DATE_TIME = (data) => {
  return {
    type: "CHECKOUT_DATE_TIME",
    payload: data,
  };
};

const SALES_TAX_MODAL = (data) => {
  return {
    type: "SALES_TAX_MODAL",
    payload: data,
  };
};
const DELIVERY_ADDRESS_MODAL = (data) => {
  return {
    type: "DELIVERY_ADDRESS_MODAL",
    payload: data,
  };
};
const DROP_OPTION = (data) => {
  return {
    type: "DROP_OPTION",
    payload: data,
  };
};

const SHOE_ADDONS_MODAL_ON_ORDER_CHECKOUT = (data) => {
  return {
    type: "SHOE_ADDONS_MODAL_ON_ORDER_CHECKOUT",
    payload: data,
  };
};

const ORDER_CHECKOUT_DEFAULT_ADDRESS = (data) => {
  return {
    type: "ORDER_CHECKOUT_DEFAULT_ADDRESS",
    payload: data,
  };
};
const GET_ADDRESS_LIST_ORDER_CHECKOUT = (data, callback) => {
  return {
    type: "GET_ADDRESS_LIST_ORDER_CHECKOUT",
    payload: data,
    callback,
  };
};
const GET_VERIFY_ADDRESS_DATE_TIME = (data, callback) => {
  return {
    type: "GET_VERIFY_ADDRESS_DATE_TIME",
    payload: data,
    callback,
  };
};
const SET_VERIFY_ADDRESS_DATE_TIME = (data) => {
  return {
    type: "SET_VERIFY_ADDRESS_DATE_TIME",
    payload: data,
  };
};
const GET_GIFT_CARD_LIST = (data, callback) => {
  return {
    type: "GET_GIFT_CARD_LIST",
    payload: data,
    callback,
  };
};
const SET_GIFT_CARD_LIST = (data) => {
  return {
    type: "SET_GIFT_CARD_LIST",
    payload: data,
  };
};

const GET_WALLET_BALANCE = (data, callback) => {
  return {
    type: "GET_WALLET_BALANCE",
    payload: data,
    callback,
  };
};
const SET_WALLET_BALANCE = (data) => {
  return {
    type: "SET_WALLET_BALANCE",
    payload: data,
  };
};

const GET_VALIDATE_COUPON_GIFT_CARD = (data, callback) => {
  return {
    type: "GET_VALIDATE_COUPON_GIFT_CARD",
    payload: data,
    callback,
  };
};

const SET_VALIDATE_COUPON_GIFT_CARD = (data) => {
  return {
    type: "SET_VALIDATE_COUPON_GIFT_CARD",
    payload: data,
  };
};

const SET_EGIFT_CARD_CODE_STATUS = (data) => {
  return {
    type: "SET_EGIFT_CARD_CODE_STATUS",
    payload: data,
  };
};

const GET_VALIDATE_GIFT_CARD = (data, callback) => {
  return {
    type: "GET_VALIDATE_GIFT_CARD",
    payload: data,
    callback,
  };
};

const SET_VALIDATE_GIFT_CARD = (data) => {
  return {
    type: "SET_VALIDATE_GIFT_CARD",
    payload: data,
  };
};

const GET_EGIFT_CARD_CODE_STATUS = (data) => {
  return {
    type: "GET_EGIFT_CARD_CODE_STATUS",
    payload: data,
  };
};

const SHOW_ADD_FUND_MODAL = (data) => {
  return {
    type: "SHOW_ADD_FUND_MODAL",
    payload: data,
  };
};

const GET_GENERATE_CLIENT_TOKEN = (data, callback) => {
  return {
    type: "GET_GENERATE_CLIENT_TOKEN",
    payload: data,
    callback,
  };
};
const SET_CLIENT_TOKEN = (data) => {
  return {
    type: "SET_CLIENT_TOKEN",
    payload: data,
  };
};

const GET_ADD_FUND = (data, callback) => {
  return {
    type: "GET_ADD_FUND",
    payload: data,
    callback,
  };
};
const GET_PAYMENT_SETTING = (data, callback) => {
  return {
    type: "GET_PAYMENT_SETTING",
    payload: data,
    callback,
  };
};
const SET_PAYMENT_SETTING = (data) => {
  return {
    type: "SET_PAYMENT_SETTING",
    payload: data,
  };
};
const SET_PAYMENT_OPTION = (data) => {
  return {
    type: "SET_PAYMENT_OPTION",
    payload: data,
  };
};
const SET_DRIVER_TIP_TYPE = (data) => {
  return {
    type: "SET_DRIVER_TIP_TYPE",
    payload: data,
  };
};

const SET_DRIVER_TIP_VALUE = (data) => {
  return {
    type: "SET_DRIVER_TIP_VALUE",
    payload: data,
  };
};
const GET_PLACE_ORDER = (data, callback) => {
  return {
    type: "GET_PLACE_ORDER",
    payload: data,
    callback,
  };
};
const SET_PLACE_ORDER = (data) => {
  return {
    type: "SET_PLACE_ORDER",
    payload: data,
  };
};

const GET_CALL_CART_API_IN_ORDER_CHECKOUT = (data) => {
  return {
    type: "GET_CALL_CART_API_IN_ORDER_CHECKOUT",
    payload: data,
  };
};
const GET_HIT_PLACE_ORDER_KEY = (data) => {
  return {
    type: "GET_HIT_PLACE_ORDER_KEY",
    payload: data,
  };
};
const SHOW_PLACE_ORDER_SUCCESS_UI = (data) => {
  return {
    type: "SHOW_PLACE_ORDER_SUCCESS_UI",
    payload: data,
  };
};
const STOP_FIRST_RENDER = (data) => {
  return {
    type: "STOP_FIRST_RENDER",
    payload: data,
  };
};
const GET_ORDER_CHECKOUT_PRICE_COUNT = (data, callback) => {
  return {
    type: "GET_ORDER_CHECKOUT_PRICE_COUNT",
    payload: data,
    callback,
  };
};
const SET_ORDER_CHECKOUT_PRICE_COUNT = (data) => {
  return {
    type: "SET_ORDER_CHECKOUT_PRICE_COUNT",
    payload: data,
  };
};

const SET_ORDER_CHECKOUT_ERROR = (data) => {
  return {
    type: "SET_ORDER_CHECKOUT_ERROR",
    payload: data,
  };
};

const SET_WALLET_GIFT_CARD_SERIES = (data) => {
  return {
    type: "SET_WALLET_GIFT_CARD_SERIES",
    payload: data,
  };
};

export {
  DRIVER_TIP,
  CHECKOUT_DATE_TIME,
  SALES_TAX_MODAL,
  DELIVERY_ADDRESS_MODAL,
  DROP_OPTION,
  SHOE_ADDONS_MODAL_ON_ORDER_CHECKOUT,
  ORDER_CHECKOUT_DEFAULT_ADDRESS,
  GET_ADDRESS_LIST_ORDER_CHECKOUT,
  GET_VERIFY_ADDRESS_DATE_TIME,
  SET_VERIFY_ADDRESS_DATE_TIME,
  GET_GIFT_CARD_LIST,
  SET_GIFT_CARD_LIST,
  GET_WALLET_BALANCE,
  SET_WALLET_BALANCE,
  GET_VALIDATE_COUPON_GIFT_CARD,
  SET_VALIDATE_COUPON_GIFT_CARD,
  SHOW_ADD_FUND_MODAL,
  GET_GENERATE_CLIENT_TOKEN,
  SET_CLIENT_TOKEN,
  GET_ADD_FUND,
  GET_PAYMENT_SETTING,
  SET_PAYMENT_SETTING,
  SET_PAYMENT_OPTION,
  SET_DRIVER_TIP_TYPE,
  SET_DRIVER_TIP_VALUE,
  GET_PLACE_ORDER,
  GET_CALL_CART_API_IN_ORDER_CHECKOUT,
  GET_HIT_PLACE_ORDER_KEY,
  SHOW_PLACE_ORDER_SUCCESS_UI,
  STOP_FIRST_RENDER,
  GET_ORDER_CHECKOUT_PRICE_COUNT,
  SET_ORDER_CHECKOUT_PRICE_COUNT,
  SET_WALLET_GIFT_CARD_SERIES,
  SET_PLACE_ORDER,
  GET_VALIDATE_GIFT_CARD,
  SET_VALIDATE_GIFT_CARD,
  GET_EGIFT_CARD_CODE_STATUS,
  SET_EGIFT_CARD_CODE_STATUS,
  SET_ORDER_CHECKOUT_ERROR
};
