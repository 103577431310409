import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import homeIcon from "assets/images/home-active.svg";
import searchIcon from "assets/images/footersearch.svg";
import accountIcon from "assets/images/footeraccount.svg";
import "./BottomNavigationMenu.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ACTIVE_ACCOUNT,
  GET_NOTIFICATION_EXIST,
  GET_SHOW_RESTAURANT_CART,
  SET_NOTIFICATION_READ,
  SHOW_BOTTOM_NAVIGATION_SEARCH,
  SHOW_SIDE_BAR,
} from "store/Action/HomeAction";
import { Button } from "react-bootstrap";
import {
  SHOW_CART,
  SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE,
} from "store/Action/RasturantMenuAction";
import notificationIcon from "assets/images/footernotification.svg";
import signInIcon from "assets/images/footerorder.svg";
import shoppingIcon from "assets/images/shopping-bag-icon.svg";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { textTruncateMore } from "helpers/textTruncate/TextTruncate";
import { GET_DATE_TIME_ON_MENU } from "store/Action/AuthAction";
import OrderSearchMobile from "pages/home/orderFromYourFavoritResturant/OrderSearchMobile";

const BottomNavigationMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const Token = localStorage.getItem("access_token");
  const pathName = location?.pathname;

  var menuId = location.pathname?.replace(/^\D+/g, "");
  var menuUrl = location.pathname?.replace(menuId, "");
  //ALL SELECTORS
  const {
    VISIBLE_SIDEBAR,
    SHOW_SEARCH_SCREEN,
    SHOW_SEARCH_MENU_SCREEN,
    SHOW_CART_OPTION,
    CART_LIST,
    PREVENT_RESTAURANT_NAME_STATE,
    DEFAULT_ADDRESS,
    SELECTED_RESTAURANT_DATA_STATE,
    NOTIFICATION_EXIST,
  } = useSelector((store) => ({
    VISIBLE_SIDEBAR: store.HomeReducer.sidebarVisible,
    SHOW_SEARCH_SCREEN: store.HomeReducer.showSearchScreen,
    SHOW_SEARCH_MENU_SCREEN:
      store.RasturantMenuReducer.showBottomNavigationSearchScreen,
    SHOW_CART_OPTION: store.HomeReducer?.showRestaurantCart ?? false,
    CART_LIST: store?.RasturantMenuReducer?.cartList ?? [],
    PREVENT_RESTAURANT_NAME_STATE:
      store?.RasturantMenuReducer?.restaurantPrvName ?? "",
    DEFAULT_ADDRESS: store.ProfileReducer.defaultAddress,
    SELECTED_RESTAURANT_DATA_STATE:
      store?.ResturantReducer?.selectedRestaurantData ?? {},
    NOTIFICATION_EXIST: store?.HomeReducer?.notificationExist ?? 0,
  }));

  //var dynamicUrl = `/${SELECTED_RESTAURANT_DATA_STATE.city_name}/${SELECTED_RESTAURANT_DATA_STATE?.restro_name}`;
  var dynamicUrl = `/${SELECTED_RESTAURANT_DATA_STATE?.slug}`;

  //ALL STATE
  const [search, setSearch] = useState();
  const [orderType, setOrderType] = useState("");
  const [showRedLabel, setShowRedLabel] = useState(0);
  const [orderSearch, setOrderSearch] = useState(false);

  const toggleCartOption = () => {
    console.log("toggleCartOption", toggleCartOption);
    dispatch(GET_SHOW_RESTAURANT_CART(!SHOW_CART_OPTION));
  };

  useEffect(() => {
    if (CART_LIST?.length !== 0) {
      const initialCart = CART_LIST[0];
      const resultOrderType =
        initialCart?.products_model == "OTHER_GIFT_CARD"
          ? "giftCard"
          : initialCart?.products_model == "GIFT_CARD"
          ? "giftCard"
          : initialCart?.products_model == "GIFT_CARD_NON_REWARDS"
          ? "giftCard"
          : initialCart?.products_model == "E_GIFT_CARD"
          ? "eGiftCard"
          : initialCart?.products_model == "E_GIFT_CARD_NON_REWARDS"
          ? "eGiftCard"
          : PREVENT_RESTAURANT_NAME_STATE;

      setOrderType(resultOrderType);
    }
  }, [CART_LIST]);

  useEffect(() => {
    if (Token) {
      fetchNotificationExist();
    }
  }, [location.pathname]);

  const fetchNotificationExist = () => {
    if (Token) {
      dispatch(
        GET_NOTIFICATION_EXIST({}, (res, loading, error) => {
          if (!error) {
            setShowRedLabel(res?.data?.is_unread_notification_exist);
          }
        })
      );
    }
  };

  const handelReadNotification = () => {
    const dataPayload = {};
    dispatch(
      SET_NOTIFICATION_READ(dataPayload, (loading, error) => {
        if (!error) {
          fetchNotificationExist();
        }
      })
    );
  };

  const handleSelectLocation = () => {
    setOrderSearch(!orderSearch);
  };

  const totalCartItem = () => {
    let total = 0;
    for (const cart of CART_LIST) {
      total += cart.customers_basket_quantity;
    }

    return total;
  };

  return (
    <>
      {orderSearch && <OrderSearchMobile handleOrder={handleSelectLocation} />}

      <div className="footer-search d-md-none d-block">
        <ul>
          <li
            className={
              !pathName || pathName === "/restaurants" ? "activeTabs" : ""
            }
          >
            <Button onClick={() => navigate("/")}>
              {" "}
              <figure>
                <img src={homeIcon} alt="" />
              </figure>
              Home
            </Button>
            {/* {Object.keys(DEFAULT_ADDRESS).length == 0 ? (
            <Button onClick={() => navigate("/")}>
              {" "}
              <figure>
                <img src={homeIcon} alt="" />
              </figure>
              Home
            </Button>
          ) : (
            <>
              {" "}
              <figure>
                <img src={homeIcon} alt="" />
              </figure>
              Home
            </>
          )} */}
          </li>
          {Token && (
            <li className={pathName === "/notification" ? "activeTabs" : ""}>
              <Button
                onClick={() => {
                  handelReadNotification();
                  navigate("/notification");
                }}
              >
                <figure className="position-relative">
                  <img src={notificationIcon} alt="" />
                  {showRedLabel === 1 && (
                    <span className="notification-count" />
                  )}
                </figure>
                Notification
              </Button>
            </li>
          )}
          {location.pathname?.toLowerCase() === dynamicUrl?.toLowerCase() ||
          pathName === "/restaurants" ? (
            <li
            // className={
            //   pathName == "/restaurants-menu/"
            //     ? "activeTabs"
            //     : pathName == "/restaurants"
            //     ? "activeTabs"
            //     : ""
            // }
            // className={
            //   SHOW_SEARCH_MENU_SCREEN
            //     ? "activeTabs"
            //     :SHOW_SEARCH_SCREEN
            //     ? "activeTabs"
            //     : ""
            // }
            >
              <Button
                onClick={() => {
                  // if (menuUrl == "/restaurants-menu/") {
                  if (
                    location?.pathname?.toLowerCase() ===
                    dynamicUrl?.toLowerCase()
                  ) {
                    dispatch(
                      SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE(
                        !SHOW_SEARCH_MENU_SCREEN
                      )
                    );
                  } else {
                    dispatch(
                      SHOW_BOTTOM_NAVIGATION_SEARCH(!SHOW_SEARCH_SCREEN)
                    );
                  }
                }}
              >
                <figure className="search-bm">
                  <img src={searchIcon} alt="" />
                </figure>
                Search
              </Button>
            </li>
          ) : (
            <li>
              <Button
                onClick={() => {
                  // navigate('/restaurants')
                  if (Object.keys(DEFAULT_ADDRESS).length > 0) {
                    dispatch(
                      SHOW_BOTTOM_NAVIGATION_SEARCH(!SHOW_SEARCH_SCREEN)
                    );
                  } else {
                    handleSelectLocation();
                  }
                }}
              >
                <figure className="search-bm">
                  <img src={searchIcon} alt="" />
                </figure>
                Search
              </Button>
            </li>
          )}
          {/* {Token && ( */}
          <li className={pathName == "/order" ? "activeTabs" : ""}>
            <Button onClick={() => navigate("/order")}>
              {" "}
              <figure>
                <img src={signInIcon} alt="" />
              </figure>
              Orders
            </Button>
          </li>
          {/* )} */}

          <li className={VISIBLE_SIDEBAR ? "activeTabs" : ""}>
            <Button
              onClick={() => {
                dispatch(GET_ACTIVE_ACCOUNT("active"));
                dispatch(SHOW_SIDE_BAR(!VISIBLE_SIDEBAR));
              }}
            >
              {" "}
              <figure>
                <img src={accountIcon} alt="" />
              </figure>
              Account
            </Button>
          </li>
        </ul>
        {/* {location?.pathname == "/restaurants" && CART_LIST?.length !== 0 && (
        <span
          onClick={() => toggleCartOption()}
          className="top-scroller restaurant-cart-icon"
        >
          <img src={shoppingIcon} alt="No_Image" />
        </span>
      )} */}
        {location?.pathname == "/restaurants" &&
          CART_LIST?.length !== 0 &&
          orderType !== "" && (
            <span>
              <div className="d-lg-none d-block">
                <div
                  className="view-cart-wrapper"
                  onClick={() => toggleCartOption()}
                >
                  <h2>
                    <img src={shoppingIcon} alt="No_Image" />
                  </h2>
                  <div className="view-item-wrapper">
                    <span> View Cart</span>
                    <h6>
                      {/* {orderType === "giftCard"
                    ? "Gift card"
                    : orderType === "eGiftCard"
                    ? "E-gift card"
                    : PREVENT_RESTAURANT_NAME_STATE} */}
                      {orderType}
                    </h6>
                  </div>
                  <strong>{totalCartItem()}</strong>
                </div>
              </div>
            </span>
          )}
      </div>
    </>
  );
};

export default BottomNavigationMenu;
