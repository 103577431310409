import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import LottiLoader from "components/shared/Loader/LottiLoader";
import SearchPlaces from "components/shared/Map/SearchPlace";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { formatter } from "utils/constants/Constants";
//MUI
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
import { Form } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import { GET_DEFAULT_ADDRESS } from "store/Action/HomeAction";
import { GET_USER_PROFILE } from "store/Action/Sidebar/SidebarAction";
import UseCurrentLocation from "./UseCurrentLocation";

const SelectPrimaryAddress = ({
  setShowAsapPopup,
  setShowAddressPopup,
  allAddress,
  addressLoading,
  handelPrimaryAddress,
  primeryAddressLoader,
  windowWidth,
  setPrimeryAddressLoader,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Token = localStorage.getItem("access_token");

  // ALL SELECTORS
  const { USER_PROFILE_INFO, DEFAULT_ADDRESS, SERVICE_TYPE } = useSelector(
    (store) => ({
      USER_PROFILE_INFO: store?.ProfileReducer?.userProfileInfo ?? {},
      DEFAULT_ADDRESS: store.ProfileReducer.defaultAddress,
      SERVICE_TYPE: store.ResturantReducer.serviceType,
    })
  );

  //ALL STATE
  const [address, setAddress] = useState("");

  const handleChange = (event) => {
    setAddress(event.replace(/^\s+/g, ""));
  };

  useEffect(() => {
    if (Token) {
      dispatch(
        GET_USER_PROFILE({}, (loading, error) => {
          // setLoader(loading);
        })
      );
    }
  }, [Token]);

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then(async (results) => {
        console.log("results", results);
        const x = formatter({ results: results });
        const {
          address,
          city,
          state,
          country,
          postal_code,
          street_number,
          route,
        } = x;
        let latlong = await getLatLng(results[0]);

        return {
          latlong: latlong,
          address: results[0].formatted_address,
          entry_street_address: address,
          entry_postcode: postal_code,
          entry_suburb:
            street_number && route
              ? street_number.concat(` ${route}`)
              : street_number
              ? street_number
              : route
              ? route
              : "",
          entry_state: state,
          entry_city: city,
          google_place_id: results[0]?.place_id,
        };
      })
      .then((latlong) => {
        console.log("latlong", latlong);
        const {
          entry_street_address,
          entry_postcode,
          entry_suburb,
          entry_state,
          entry_city,
          google_place_id,
        } = latlong;
        setAddress(entry_street_address);

        var addressData = {};

        if (Token) {
          addressData = {
            address_lat: latlong.latlong.lat,
            address_lng: latlong.latlong.lng,
            entry_street_address,
            entry_street_name: entry_suburb,
            entry_postcode,
            entry_suburb,
            entry_state,
            entry_city,
            entry_firstname: USER_PROFILE_INFO?.customers_firstname,
            entry_lastname: USER_PROFILE_INFO?.customers_lastname,
            google_place_id,
          };
        } else {
          addressData = {
            address_lat: latlong.latlong.lat,
            address_lng: latlong.latlong.lng,
            entry_street_address,
            entry_street_name: entry_suburb,
            entry_postcode,
            entry_suburb,
            entry_state,
            entry_city,
            google_place_id,
          };
        }

        console.log("addressDataaddressData", addressData);
        if (Object.keys(addressData).length) {
          if (window.location.path !== "/restaurants") {
            const perviousAddress = Object.assign({}, DEFAULT_ADDRESS);
            if (perviousAddress.entry_postcode !== addressData.entry_postcode) {
              navigate("/restaurants");
            }
          }

          dispatch(GET_DEFAULT_ADDRESS({ ...addressData }));
        }
        handelClosePopup();
      })

      .catch((error) => console.error("Error", error));
  };

  const handelClosePopup = () => {
    setShowAsapPopup(false);
    setShowAddressPopup(false);
  };

  return (
    <div>
      <div className="picktime-mobile-picktime address_picker_main">
        <div className="address_popup_header">
          <h3 class="mb-0">
            {SERVICE_TYPE === 1 ? "Deliver To" : "Pickup Near"}
          </h3>
          <Button
            className="bg-transparent border-0 text-dark p-0 ms-auto d-block mb-3"
            onClick={() => handelClosePopup()}
          >
            <CloseIcon />
          </Button>
        </div>

        <Form className="position-relative">
          <SearchPlaces
            address={address}
            handleChange={handleChange}
            handleSelect={handleSelect}
            value={address}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <Form className="position-relative header-autocomplete-dropdown select_location_form">
                  <Form.Control
                    {...getInputProps({
                      placeholder: "Search Places ...",
                      className: "location-search-input choose-date mb-0",
                    })}
                    type="text"
                    placeholder="Search address"
                  />
                </Form>
                <div className="loder-search position-absolute start-0 end-0 text-center mt-4">
                  {loading && (
                    <div>
                      <LottiLoader loader={true} />
                    </div>
                  )}
                </div>
                {!loading && suggestions?.length !== 0 && (
                  <div className="autocomplete-dropdown-container restaurants-mobile-view">
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          key={index}
                        >
                          <span>
                            {suggestion?.description
                              ?.replace(",", "")
                              ?.replace(",", "")
                              ?.replace(", USA", "")}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </SearchPlaces>
          {windowWidth < 767 && (
            <div className="mt-2">
              <UseCurrentLocation
                windowWidth={windowWidth}
                handelClosePopup={handelClosePopup}
                setPrimeryAddressLoader={setPrimeryAddressLoader}
                primeryAddressLoader={primeryAddressLoader}
              />
            </div>
          )}
          {Token && (
            <div className="saved-address-modal">
              <p> Saved Addresses</p>
              {primeryAddressLoader ? (
                <LottiLoader loader={true} height={80} width={80} />
              ) : (
                <ul className="addressLists">
                  {allAddress &&
                    allAddress?.length !== 0 &&
                    allAddress?.map((item, index) => {
                      return (
                        <li
                          className={index == 0 ? "active" : ""}
                          key={item?.address_book_id}
                          onClick={() => handelPrimaryAddress(item)}
                        >
                          {item?.entry_street_name
                            ? item?.entry_street_name?.replace(",", "")
                            : item?.entry_street_address?.replace(",", "")}
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default SelectPrimaryAddress;
